<section class="about-section">
  <div class="container">
    <h3><div style="font-family: courier new">The SandScript QI IDE</div></h3>
    <div style="font-family: courier new">
    <p>
      A common challenge with MAPPER is that older programs which use @SC or @OUT or even many that are GUI already often give MAPPER applications an antiquated look. And many MAPPER developers who are geniuses at database design and development are not as well-versed in GUI design and development. And GUI development in any language is neither easy nor quick without an IDE (integrated development environment). If you want to quickly overhaul the look of all your MAPPER applications, or you just want to do some prototyping, you will want to try out <font style="font-family: courier new; font-weight: bold;">QI</font>. SandScript <font style="font-family: courier new; font-weight: bold;">QI</font> can help you make wonderful screens all by yourself, but if you want SandScript Software to help you do this at lightning speed, give us a call. 
      <br><br>
      If any of this interests you, please watch our videos below.
      If you're keen to explore SandScript <font style="font-family: courier new; font-weight: bold;">QI</font> firsthand, experience it in our MAPPER Sandbox in the cloud. 
      Simply visit our<a class="nav-link" href="/about" style="display: inline;">Contact Us</a>page, sign up, and we'll swiftly send you an email with all the details to begin your journey!
    </p>
  </div>
  </div>
</section>
<br>
<div class="container">
  <div class="row">
    <div class="col-md-4 mb-4">
      <nb-card>
        <nb-card-header>
          <div style="font-family: courier new">Introduction to QI</div>
        </nb-card-header>
        <nb-card-body>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/n2-3cqltB5M" frameborder="0" allowfullscreen></iframe>
        </nb-card-body>
      </nb-card>
    </div>

    <div class="col-md-4 mb-4">
      <nb-card>
        <nb-card-header>
          <font style="font-family: courier new; font-weight: bold;">QI</font> Demo with Introduction
        </nb-card-header>
        <nb-card-body>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/WAToQOzirxA" frameborder="0" allowfullscreen></iframe>
        </nb-card-body>
      </nb-card>
    </div>

    <div class="col-md-4 mb-4">
      <nb-card>
        <nb-card-header>
          <font style="font-family: courier new; font-weight: bold;">QI</font> Operations
        </nb-card-header>
        <nb-card-body>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/pTTpzqJZOjU" frameborder="0" allowfullscreen></iframe>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>

<section class="about-section">
  <div class="features-section">
  <h5><font style="font-family: courier new; font-weight: bold;">QI</font> Objects</h5>
  <div class="container">
    <div class="row">
      <div class="col-md-4 mb-4">
        <div class="qi-video">
          <h6>QBUTTONS</h6>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/kDOKuF9esx0" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="qi-video">
          <h6>QCHART</h6>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/CGb1XrQAaWs" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="qi-video">
          <h6>QCHECKS</h6>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/4otWQB_mkIs" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="qi-video">
          <h6>QDATE</h6>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/blfzn7JM3tY" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="qi-video">
          <h6>QFUN</h6>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/3-qVIHNkSr4" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="qi-video">
          <h6>QLST</h6>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/PdzK7qli7Qg" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="qi-video">
          <h6>QRADIOS</h6>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/qKfvK_dwx_Q" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="qi-video">
          <h6>QSLIDER</h6>
          <iframe class="video-iframe" src="https://www.youtube.com/embed/4k1BAtbJiwc" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
</section>