import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import features from 'src/assets/files/features.json';
import { MapperapiService } from '../../services/mapperapi.service';
import { MessagesService } from '../../services/messages.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Validation from '../../services/validation';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mapperanywhere',
  templateUrl: './mapperanywhere.component.html',
  styleUrls: ['./mapperanywhere.component.scss']
})
export class MapperanywhereComponent implements OnInit {

  form: FormGroup = new FormGroup({
    fname: new FormControl(''),
    lname: new FormControl(''),
    email: new FormControl(''),
    compn: new FormControl(''),
    title: new FormControl(''),
    descn: new FormControl(''),
  });

  submitted = false;
  featuresData:object;

  constructor(private formBuilder: FormBuilder
    ,public ngxSmartModalService: NgxSmartModalService
    , public api: MapperapiService
    ,public messages: MessagesService,
    private route:Router) {
      this.featuresData = features;
    }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        fname: ['', Validators.required],
        lname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        compn: ['', Validators.required],
        title: ['', Validators.required],
        descn: ['', Validators.required]
      }   
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  setModalWindoData(event, featureData){
    this.ngxSmartModalService.setModalData(featureData, 'myModal', true);
    this.ngxSmartModalService.getModal('myModal').open();
  }

  register() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let params = {
      "NGprojID": "MAPPER",
      "NGprocID": "SANDBOX",
      "fname": this.f.fname.value,
      "lname": this.f.lname.value,
      "email": this.f.email.value,
      "compn": this.f.compn.value,
      "title": this.f.title.value,
      'descn': this.f.descn.value,
    }
    
    this.api.postMapperData(params, null, null).subscribe(result => {
      console.log(result);
      if (result && result[0] && result[0].ok && result[0].ok == "") {
        this.messages.msgNoButton("Error while saving Information", "danger", null, null, null);
      }
      else{
        this.messages.msgNoButton("Information saved successfully", "success", null, null, null)
        //this.messages.msgFromApiResult(result);
      }
    });

  }

  /*
  validation(){

    if (this.fname == "") {
      this.messages.msgNoButton("First Name is mandatory", "warning", null, null, null);
      return false;
    }

    if (this.lname == "") {
      this.messages.msgNoButton("Last Name is mandatory", "warning", null, null, null);
      return false;
    }

    if (this.email == "") {
      this.messages.msgNoButton("Email is mandatory", "warning", null, null, null);
      return false;
    }
    else{
      if(!this.isValidEmail(this.email)){
        this.messages.msgNoButton("Email is invalid", "warning", null, null, null);
        return false;
      }
    }

    if (this.compn == "") {
      this.messages.msgNoButton("Company Name is mandatory", "warning", null, null, null);
      return false;
    }

    if (this.title == "") {
      this.messages.msgNoButton("Title is mandatory", "warning", null, null, null);
      return false;
    }

    if (this.descn == "") {
      this.messages.msgNoButton("Message is mandatory", "warning", null, null, null);
      return false;
    }

    return true;

  }
  */
}
