<section class="about-section">
  <div class="container">
    <h5>About SandScript Software</h5>
    <p>At SandScript Software, we're focused on enhancing MAPPER technology to meet modern demands. Our expertise lies in upgrading MAPPER systems to support mobile apps, sophisticated software design, and contemporary user interfaces. Our commitment is to provide innovative solutions that breathe new life into MAPPER, making it more dynamic, efficient, and user-friendly. Join us as we advance MAPPER technology into a new era of efficiency and accessibility.</p>
  </div>
</section>
<br>
<section class="col-xs-12 col-sm-12 col-md-10 col-lg-8 align-items-center d-flex" 
style="text-align:center; margin-left: auto; margin-right: auto;">
<div style="margin: 0 auto;">
    <nb-card>
        <nb-card-header>
            <div class="row" style="width: 100%;">
                <div style="display: inline; width: 90%; text-align: left;">
                    Contact Us
                </div>
                <!--<div style="display: inline; width: 10%;">
                    <img src="/assets/MapperAppImages/MapperAnywhereLogo.png" height="50">
                </div>-->
            </div>
        </nb-card-header>   
        <nb-card-body>
            <div class="row mt-5" >
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="media pt-2">
                        <p><i class="fa-solid fa-map-marker fa-3x" [ngStyle]="{ 'color': '#F4D58D' }"></i></p>
                        <p class="pmd-list-subtitle text-white" style="padding-left: 10px;">
                            <strong>SandScript Software</strong><br>
                            Sherman, CT - 06784
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="media pt-2">
                        <p><i class="fa-solid fa-phone fa-3x" [ngStyle]="{ 'color': '#F4D58D' }"></i></p>
                        <p class="pmd-list-subtitle text-white" style="padding-left: 10px;"><a class="text-white"
                                href="tel:+1 (860) 898-0127">+1 (860) 898-0127</a></p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="media pt-2">
                        <p><i class="fa-solid fa-envelope fa-3x" [ngStyle]="{ 'color': '#F4D58D' }"></i></p>
                        <p class="pmd-list-subtitle" style="padding-left: 10px;">&nbsp;<a class="text-white"
                                href="mailto:info@mapperanywhere.com">info@mapperanywhere.com</a></p>
                    </div>
                </div>
                <div class="col-lg-12 col-md-6 mb-4">
                    <hr>
                    <p style="text-align: left;">
                        Please register here to receive an email guiding you to our MAPPER sandbox in the cloud where you can try out either of these exciting new products yourself.
                    </p>
                    <form [formGroup]="form"(ngSubmit)="register()" >
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <input type="text" nbInput class="form-control" placeholder="First name"
                                        formControlName="fname" [ngClass]="{ 'is-invalid': submitted && f['fname'].errors }">
                                    <div *ngIf="submitted && f['fname'].errors" class="invalid-feedback">
                                        <div *ngIf="f['fname'].errors['required']">First Name is required</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <input type="text" nbInput class="form-control" placeholder="Last name"
                                        formControlName="lname" [ngClass]="{ 'is-invalid': submitted && f['lname'].errors }">
                                    <div *ngIf="submitted && f['lname'].errors" class="invalid-feedback">
                                        <div *ngIf="f['lname'].errors['required']">Last Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    <input type="text" nbInput fullWidth class="form-control" placeholder="Enter email"
                                        formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">
                                    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                        <div *ngIf="f['email'].errors['required']">Email is required</div>
                                        <div *ngIf="f['email'].errors['email']">Email is invalid</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    <input type="text" nbInput fullWidth class="form-control" placeholder="Title"
                                        formControlName="title" [ngClass]="{ 'is-invalid': submitted && f['title'].errors }">
                                    <div *ngIf="submitted && f['title'].errors" class="invalid-feedback">
                                        <div *ngIf="f['title'].errors['required']">Title is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    <input type="text" nbInput fullWidth class="form-control" placeholder="Company Name"
                                        formControlName="compn" [ngClass]="{ 'is-invalid': submitted && f['compn'].errors }">
                                    <div *ngIf="submitted && f['lname'].errors" class="invalid-feedback">
                                        <div *ngIf="f['compn'].errors['required']">Company Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    <textarea nbInput fullWidth class="form-control" formControlName="descn" rows="3"
                                        placeholder="How can we help?"
                                        [ngClass]="{ 'is-invalid': submitted && f['descn'].errors }">
                                    </textarea>
                                    <div *ngIf="submitted && f['descn'].errors" class="invalid-feedback">
                                        <div *ngIf="f['descn'].errors['required']">Description is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col">
                                    <button type="reset" nbButton style="background-color:goldenrod; color: white;">
                                        Reset</button>
                                </div>
                                <div class="col">
                                    <button type="submit" nbButton style="background-color: green; color: white;">
                                        Send</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>
</section>

<section class="pricing-section">
  <div class="container">
    <h5>Pricing</h5>
    <p>Pricing is personalized based on your specific requirements and usage scope. We have monthly plans and one-time flat fee plans and are always open to consider your specific situation. For a detailed consultation and to discuss your pricing options, please contact our sales team at info@MAPPERAnywhere.com.</p>
  </div>
</section>
<br>
<section class="pricing-section">
  <div class="container">
    <p>
      At SandScript Software LLP, our mission is to redefine the landscape of MAPPER technology, bringing a new era of modernization and support to MAPPER developers worldwide. Our dedication is not just to enhance the existing systems but to revolutionize the way MAPPER is used and perceived in the rapidly evolving tech world.
<br><br>In an age where technology moves at lightning speed, we understand the critical need for systems that not only keep pace but set the standard. That's why we're committed to transforming your MAPPER system, ensuring it not only meets but exceeds the demands of today's dynamic tech environment. Our focus is on elevating MAPPER to new heights, enabling it to support sophisticated mobile applications, embrace advanced software design principles, and offer interfaces that are not just functional, but also modern and engaging.
<br><br>We believe in the untapped potential of MAPPER technology and are here to unlock it. Whether you're looking to bring mobility to your systems or seeking to refresh your user interface, SandScript Software LLP is your partner in innovation. Our team, driven by passion and expertise, is dedicated to providing solutions that don’t just solve current challenges but pave the way for future advancements.
<br><br>At SandScript Software, we're more than just a company; we're a community of enthusiasts and professionals, united in the goal of taking MAPPER to the next level. Join us on this journey of transformation and discover the endless possibilities.
    </p>
  </div>
</section>


<div class="overlay" [style.display]="isSubmitting ? 'block' : 'none'">
    <!-- Loading spinner -->
    <div class="spinner">
      <!-- You can replace this with your loading animation or message -->
      Processing...
    </div>
  </div>