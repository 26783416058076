import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface Video {
  title: string;
  embedUrl: SafeResourceUrl;
}

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit {
  trustedVideoUrlArray: SafeResourceUrl[] = [];
  videos: Video[] = [];

  youtubeUrlsArray = [
    {
      title: 'Video 1',
      embedUrl: 'https://youtube.com/embed/n2-3cqltB5M?autoplay=0&controls=1'
    },
    {
      title: 'Video 2',
      embedUrl: 'https://youtube.com/embed/WAToQOzirxA?autoplay=0&controls=1'
    },
    // Add more videos as needed
  ];

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.populateVideos();
  }

  populateVideos(): void {
    for (let item of this.youtubeUrlsArray) {
      const sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(item.embedUrl);
      this.trustedVideoUrlArray.push(sanitizedUrl);
      this.videos.push({
        title: item.title,
        embedUrl: sanitizedUrl
      });
    }
  }

}
