
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxLoggerLevel, NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { appconfig } from '../appconfig';
import { PostParams } from '../models/post-params';
import { NavParamService } from '../services/navparam.service';

//Enum used to loglevel - one to one match with NgxLoggerLevel
export enum LogLevel {
  TRACE = NgxLoggerLevel.TRACE,
  DEBUG = NgxLoggerLevel.DEBUG,
  INFO = NgxLoggerLevel.INFO,
  LOG = NgxLoggerLevel.LOG,
  WARN = NgxLoggerLevel.WARN,
  ERROR = NgxLoggerLevel.ERROR,
  FATAL = NgxLoggerLevel.FATAL,
}

/**
 * The LogEvent is emitted by the LoggerFormComponent so that the log can be performed by the parent.
 */
export class LogEvent {
  logMessage: string;
  logType: LogLevel;
  loggingHost: string;
  consoleLog: string;

  constructor(logMessage: string, logType: LogLevel, loggingHost: string, consoleLog: string) {
    this.logMessage = logMessage;
    this.logType = logType;
    this.loggingHost = loggingHost;
    this.consoleLog = consoleLog;
  }
}

@Injectable({
  providedIn: 'root'
})

export class HandleLogService {
  httpOptions: { headers: HttpHeaders; };
  postParams: PostParams;

  constructor(
    public http: HttpClient,
    private navParams: NavParamService,
    private logger: NGXLogger) {
  }

  /**
     * Logs the user input using NGXLogger
     * @param log
     */
  async Logs(log: LogEvent) {
    switch (log.logType) {
      case LogLevel.TRACE:
        if (log.consoleLog == "Yes") {
          this.logger.trace(log.logMessage);
        }
        this.logInMapper(log.logMessage, "TRACE", log.loggingHost);
        break;
      case LogLevel.DEBUG:
        if (log.consoleLog == "Yes") {
          this.logger.debug(log.logMessage);
        }
        this.logInMapper(log.logMessage, "DEBUG", log.loggingHost);
        break;
      case LogLevel.INFO:
        if (log.consoleLog == "Yes") {
          this.logger.info(log.logMessage);  // console.log("");
        }
        this.logInMapper(log.logMessage, "INFO", log.loggingHost);
        break;
      case LogLevel.LOG:
        if (log.consoleLog == "Yes") {
          this.logger.log(log.logMessage);
        }
        this.logInMapper(log.logMessage, "LOG", log.loggingHost);
        break;
      case LogLevel.WARN:
        if (log.consoleLog == "Yes") {
          this.logger.warn(log.logMessage);
        }
        this.logInMapper(log.logMessage, "WARN", log.loggingHost);
        break;
      case LogLevel.ERROR:
        if (log.consoleLog == "Yes") {
          this.logger.error(log.logMessage);
        }
        this.logInMapper(log.logMessage, "ERROR", log.loggingHost);
        break;
      case LogLevel.FATAL:
        if (log.consoleLog == "Yes") {
          this.logger.fatal(log.logMessage);
        }
        this.logInMapper(log.logMessage, "FATAL", log.loggingHost);
        break;
    }
  }

  async logInMapper(message: string, type: string, loggingHost: string){
    if (!loggingHost) { return; }
    let user: string = "";
    if (this.navParams.get()) {
      user = this.navParams.get().user;
    }

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.postParams = {
      //"UserId": appconfig.mapperuid,
      //"Pass": appconfig.mapperpass,
      //"Dept": appconfig.mapperdept,
      "spKVs": ''
    };

    let postData = {
      "NGprojID": "MAPPER",
      "NGprocID": "LOGGER",
      "user": user,
      "type": type,
      "message": message
    };

    this.postParams['spKVs'] = JSON.stringify(postData);
    let mapperUrl: string = appconfig.mapperapiUrl;
    mapperUrl = mapperUrl.replace("[host]", loggingHost);
    this.http.post(mapperUrl, this.postParams, this.httpOptions).subscribe(result => {});
  }

  /**
     * Updates the logger config to the new log level
     * @param newLevel
     */

  //To disable console log
  handleDisableConsoleLog(disableFileDetails: boolean) {
    const updatedConfig = this.logger.getConfigSnapshot();
    updatedConfig.disableConsoleLogging = disableFileDetails;
    this.logger.updateConfig(updatedConfig);
  }

  //To turn on server logging
  serverLogging(enabled: boolean, serverLogLevel: NgxLoggerLevel) {
    const updatedConfig = this.logger.getConfigSnapshot();
    updatedConfig.serverLoggingUrl = enabled ? environment.logging.serverLoggingURL : null;
    updatedConfig.serverLogLevel = serverLogLevel;
    this.logger.updateConfig(updatedConfig);
  }

  //To change Minimum loglevel
  handleLogLevelChange(newLevel: NgxLoggerLevel) {
    const updatedConfig = this.logger.getConfigSnapshot();
    updatedConfig.level = newLevel;
    this.logger.updateConfig(updatedConfig);
  }

  //To turn on file details in logging
  handleDisableFileDetails(disableFileDetails: boolean) {
    const updatedConfig = this.logger.getConfigSnapshot();
    updatedConfig.disableFileDetails = disableFileDetails;
    this.logger.updateConfig(updatedConfig);
  }

}
