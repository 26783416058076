import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { appconfig } from '../appconfig';
import { PostParams } from '../models/post-params';
import { LogLevel, LogEvent, HandleLogService } from '../services/handle-log.service';
import { MessagesService } from '../services/messages.service';

@Injectable({
  providedIn: 'root'
})
export class MapperapiService {

  constructor(
    public messages: MessagesService,
    public http: HttpClient,
    public postParams: PostParams,
    private logger: HandleLogService,
    @Inject('mapperURL') @Optional() public mapperURL?: any,
    @Inject('httpOptions') @Optional() public httpOptions?: any) {

    this.mapperURL = appconfig.mapperapiUrl;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.postParams = {
      //"UserId": appconfig.mapperuid,
      //"Pass": appconfig.mapperpass,
      //"Dept": appconfig.mapperdept,
      "spKVs": ''
    };
  }

  postMapperData(postData: any, loggingHost: string, consoleLog: string): Observable<any> {
    let json: string = JSON.stringify(postData);
    if (json.indexOf("password") != -1) {
      json = json.substring(0, json.indexOf("password")) + "password\":\"••••••••\"}";
    }
    if (json.indexOf("token") != -1) {
      json = json.substring(0, json.indexOf("token")) + "token\":\"••••••••\"}";
    }
    this.logger.Logs(new LogEvent(" MAPPER API call: " + json, LogLevel.INFO, loggingHost, consoleLog));
    this.postParams['spKVs'] = postData;
    return this.http.post(this.mapperURL, postData, this.httpOptions);
  }

  validateUser(host: string, user: string, dept: string, from: string) {
    let params = {
      "NGprojID": "MAPPER",
      "NGprocID": "VALUSER",
      "host": host,
      "user": user,
      "dept": dept,
      "from": from
    }

    this.postMapperData(params, null, null).subscribe(result => {
      if (result && result[0] && result[0].ok && result[0].ok == "OK") {
      } else {
        
      }
      this.messages.msgFromApiResult(result);
    });
  }

}
