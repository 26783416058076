import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavParamService {
  navData: any;
  constructor() { }

  set(navObj) {
    this.navData = navObj;
  }

  get() {
    if (this.navData === null || this.navData === undefined) {
      return null;
    }
    return this.navData;
  }
}
