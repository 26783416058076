import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import features from 'src/assets/files/features.json';
import { MapperapiService } from '../../services/mapperapi.service';
import { MessagesService } from '../../services/messages.service';
import Validation from '../../services/validation';
import {Router} from '@angular/router';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {
  form: FormGroup = new FormGroup({
    fname: new FormControl(''),
    lname: new FormControl(''),
    email: new FormControl(''),
    compn: new FormControl(''),
    title: new FormControl(''),
    descn: new FormControl(''),
  });

  isSubmitting: boolean = false;
  submitted = false;
  featuresData:object;

  constructor(private formBuilder: FormBuilder
    ,public ngxSmartModalService: NgxSmartModalService
    , public api: MapperapiService
    ,public messages: MessagesService,
    private route:Router) {
      this.featuresData = features;
    }

    ngOnInit(): void {
      this.form = this.formBuilder.group(
        {
          fname: ['', Validators.required],
          lname: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          compn: ['', Validators.required],
          title: ['', Validators.required],
          descn: ['', Validators.required]
        }   
      );
    }

    get f(): { [key: string]: AbstractControl } {
      return this.form.controls;
    }
  
    setModalWindoData(event, featureData){
      this.ngxSmartModalService.setModalData(featureData, 'myModal', true);
      this.ngxSmartModalService.getModal('myModal').open();
    }
  
    register() {

      this.submitted = true;
      if (this.form.invalid) {
        return;
      }

      this.isSubmitting = true;
  
      let params = {
        "NGprojID": "MAPPER",
        "NGprocID": "SANDBOX",
        "fname": this.f.fname.value,
        "lname": this.f.lname.value,
        "email": this.f.email.value,
        "compn": this.f.compn.value,
        "title": this.f.title.value,
        'descn': this.f.descn.value,
      }
      
      this.api.postMapperData(params, null, null).subscribe(result => {
        console.log(result);
        if (result && result[0] && result[0].ok && result[0].ok == "") {
          this.messages.msgNoButton("Error while saving Information", "danger", null, null, null);
          this.isSubmitting = false;
        }
        else{
          this.messages.msgNoButton("Registration successful - please check your email inbox", "success", null, null, null);
          this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.route.navigate([window.location.pathname]);
          });
          this.isSubmitting = false;
          console.log(this.isSubmitting);
          //this.messages.msgFromApiResult(result);
        }
      });
  
    }

    clearForm() {

      this.form.reset({
            'fname': '',
            'lname': '',
            'email': '',
            'compn': '',
            'title': '',
            'descn': ''
           });
      }

}
