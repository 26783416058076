<div class="card-container">
    <nb-card class="card-item" (click)="navigateTo('mapperanywhere')">
      <nb-card-header>
        <div style="font-family: courier new;">MAPPERAnywhere</div>
      </nb-card-header>
      <nb-card-body>
        MAPPERAnywhere is like MAPPER Presentation Client for your smart phone (only better!).
      </nb-card-body>
    </nb-card>
    
    <nb-card class="card-item" (click)="navigateTo('qi')">
      <nb-card-header>
        <div style="font-family: courier new;">SandScript QI</div>
      </nb-card-header>
      <nb-card-body>
        The best GUI screen designer MAPPER has ever had!!!
      </nb-card-body>
    </nb-card>
    
    <nb-card class="card-item" (click)="navigateTo('about')">
      <nb-card-header>
        <div style="font-family: courier new;">Contact Us</div>
      </nb-card-header>
      <nb-card-body>
        Register today to get access for MAPPERAnywhere and <font style="font-family: courier new; font-weight: bold;">QI</font>.
      </nb-card-body>
    </nb-card>
  </div>  