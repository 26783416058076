<div class="video-container">
    <div class="video" *ngFor="let video of videos">
      <iframe width="560" height="315" [src]="video.embedUrl" 
      frameborder="0" allowfullscreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" 
      >
    </iframe>
      <h3>{{ video.title }}</h3>
    </div>
  </div>
  