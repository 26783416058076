<div class="header-container row align-items-center">

    <div class="col-7 col-md-6 col-lg-5 d-flex">
        <a class="logo" href="#" (click)="navigateHome()">
            <p><img id="appLogo" src="/assets/MapperAppImages/MapperAnywhereLogo.png" height="100"></p>
            <!-- <p><img id="appLogo" src="/assets/MapperAppImages/qisplash.png" height="100"></p> -->
        </a>
    </div>

    <div class="col-5 col-md-6 col-lg-5">
        <nav class="navbar navbar-expand-lg">

            <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
                <span style="background: white">
                    <nb-icon icon="menu" large></nb-icon>
                </span>
            </button>

            <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" >
                <ul class="navbar-nav mr-auto" style="background-color: #222b45;">
                    <li class="nav-item">
                        <a class="nav-link" href="/">Home</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/mapperanywhere">MAPPERAnywhere</a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link" href="/sandscript">SandScript <font style="font-family: courier new; font-weight: bold;">QI</font></a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/about">Contact Us</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>