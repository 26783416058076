export const appconfig = {

    //mapper related data
    mapperapiUrl: 'https://sandscript.biz/APIandCL/dal/Generic',
    //mapperapiUrl: 'https://sandscript.biz/APIandCL/dal/NYCWAYJSON',
    // mapperapiUrl: 'http://sandscript.biz/APIandCL/dal/NYCWAYJSON',
    // mapperapiUrl: 'http://localhost:8001/CommonAPIs/dal/NYCWAYJSON',
      mapperuid: 'ciremote',
      mapperpass: 'cir',
      mapperdept: '7'
    };