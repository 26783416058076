import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogLevel, LogEvent, HandleLogService } from '../services/handle-log.service';
import { NotificationService } from './notification.service'


@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(
    private router: Router,
    private logger: HandleLogService,
    private notifyService : NotificationService) {

  }

  msgFromApiResult(result?: any) {
    let msg: string = "Unhandled Exception";
    let color: string = "danger";
    let pos: "bottom" | "middle" | "top" = "middle";
    let dur: string = "BTN";
    let go: string = "[back]";
    let header = "API Failure";

    if (result && result[2] && result[2] && result[2].lso_rt) {
      let api: string = result[2].value;
      go = "/";
      msg = "API (" + api + ") does not exist";  // error message returned
      this.msgWithButton(msg, color, header, go);
      return;
    }

    if (result && result[0] && result[0] && result[0].lso_rt) {
      let apimsg: string = result[0].value;
      go = "/";
      msg = "API message: \"" + apimsg + "\"";  // error message returned
      this.msgWithButton(msg, color, header, go);
      return;
    }

    if (result && !result[0]) {
      msg = "Nothing to display";
      color = "warning";
      pos = "middle";
      dur = "2";
      go = "";
      header = "No Data";
    }

    if (result && (result[0]) && (result[0].msg)) {
      msg = result[0].msg;  // error message returned
      color = result[0].color;
      pos = result[0].pos;
      dur = result[0].dur;
      go = result[0].go;
      header = result[0].header;
      if (go == "") { go = null; }
      if (header == "") { header = null; }
      if (color == "") { return };
      if (dur != "BTN") {
        this.msgNoButton(msg, color, header, pos, parseFloat(dur) * 1000, go);
      } else {
        this.msgWithButton(msg, color, header, go);
      }
    }
  }

  async msgNoButton(msg: string, color?: string, header?: string, pos?: "bottom" | "top" | "middle", dur?: number, go?: string) {
    if (!color) { color = "primary" }
    if (!pos) { pos = "bottom" }
    if (!dur) { dur = 2000 }
    this.logSwitch("Message No Button", msg, color);

    switch (color) {
      case "danger":
        this.notifyService.showError(msg, header);
        break;
      case "warning":
        this.notifyService.showWarning(msg, header);
        break;
      case "success":
        this.notifyService.showSuccess(msg, header);
        break;
      default:
        this.notifyService.showInfo(msg, header);
        break;
    }
    
    /*let cssClass: string = "msg-" + color + "-class"
    const toast = await this.toastController.create({
      header: header,
      message: msg,
      position: pos,
      cssClass: cssClass,
      duration: dur
    });
    toast.present();*/
    
  }

  async msgWithButton(msg: string, color?: string, header?: string, go?: string) {
    if (!color) { color = "primary" }
    this.logSwitch("Message With Button", msg, color);
    /*let cssClass: string = "msg-" + color + "-class"
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      cssClass: cssClass,
      buttons: [
        {
          cssClass: cssClass,
          text: 'Close',
          role: 'cancel',
          handler: () => {
            this.commonsubs.navSwitch(go, 0);  //  possible navigation to another page
          }
        }
      ]
    });
    alert.present();*/
  }

  

  async logSwitch(msgPhrase: string, msg: string, color: string) {
    let loggingHostFromStorage: string = null;
    let consoleLogFromStorage: string = null;
    
    switch (color) {
      case "danger":
        this.logger.Logs(new LogEvent(msgPhrase + ": " + msg, LogLevel.ERROR, loggingHostFromStorage, consoleLogFromStorage));
        break;
      case "warning":
        this.logger.Logs(new LogEvent(msgPhrase + ": " + msg, LogLevel.WARN, loggingHostFromStorage, consoleLogFromStorage));
        break;
      default:
        this.logger.Logs(new LogEvent(msgPhrase + ": " + msg, LogLevel.INFO, loggingHostFromStorage, consoleLogFromStorage));
        break;
    }
  }
}
