import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-open-modal',
  templateUrl: './open-modal.component.html',
  styleUrls: ['./open-modal.component.scss']
})
export class OpenModalComponent implements OnInit, OnDestroy {

  constructor(public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    
  }

}
