<br>
<section class="col-xs-12 col-sm-12 col-md-8 col-lg-6 align-items-center d-flex" 
style="text-align:center; margin-left: auto; margin-right: auto;">
    <nb-card>
        <nb-card-body>
            You are a MAPPER professional.
            Have you ever thought what you might be able to do if you could have meaningful access to all your MAPPER
            sites on your mobile phone?
            MAPPERAnywhere is like Mapper Presentation Client for your smart phone (only better!).
            <br><br>
            If you're keen to explore MAPPERAnywhere firsthand, experience it in our MAPPER Sandbox in the cloud.
            Simply visit our<a class="nav-link" href="/about" style="display: inline;">Contact Us</a> page, sign up, and we'll swiftly send you an email with all the details to begin your journey!
        </nb-card-body>
    </nb-card>
</section>
<section class="row justify-content-center align-items-center">
    <div class="col-md-6 mb-6 text-center">
        <nb-card>
            <nb-card-header>
            To try MAPPERAnywhere right now for free, please watch this video.
            </nb-card-header>
            <nb-card-body>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/vgiFUx_1xb4" frameborder="0" allowfullscreen></iframe>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</section>
<section class="col-xs-12 col-sm-12 col-md-10 col-lg-8 align-items-center d-flex" 
 style="text-align:center; margin-left: auto; margin-right: auto;">
    <div class="container">
        <div class="row text-center justify-content-center mt-5 pt-5">
            <div class="col-6 col-sm-4 col-md-4 col-lg-3 m-md-auto" *ngFor="let item of featuresData; let i = index;">
                <a (click)="setModalWindoData($event, item);" style="cursor:pointer">
                    <div ng-switch="item.icon">
                        <div ng-switch-default>
                            <p><i class="{{item.icon}}" [ngStyle]="{ 'color': item.color }"></i></p>
                        </div>
                        <div ng-switch-when="">
                            <p>
                                <img class="fdb-icon" *ngIf="item.iconImage;"
                                    src="/assets/MapperAppImages/{{item.iconImage}}" />
                            </p>
                        </div>
                    </div>
                    <p>{{item.text}}</p>
                </a>
                <br>
                <br>
            </div>
        </div>
    </div>
</section>
