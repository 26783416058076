    <div class="row align-items-center">
      <!-- Grid column -->
      <div class="col-md-7 col-lg-8 text-center text-md-start">
        <!-- Copyright -->
        <div class="p-3">
            ©2024 SandScript Software <img src="/assets/MapperAppImages/sandscript_blank.png" height="15" width="15" alt="image">
        </div>
        <!-- Copyright -->
      </div>
      <!-- Grid column -->
      
      <!-- Grid column -->
      <div class="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
        <!-- Facebook -->
        <!--
        <a
           class="btn btn-outline-light btn-floating m-1"
           class="text-white"
           role="button">
           <i class="fab fa-facebook-f"></i>
        </a>
        -->

        <!-- Twitter -->
        <!--
        <a
           class="btn btn-outline-light btn-floating m-1"
           class="text-white"
           role="button">
           <i class="fab fa-twitter"></i>
        </a>
        -->

      </div>
      <!-- Grid column -->
    </div>