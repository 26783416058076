import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { MyTodoPageComponent } from './pages/my-todo-page/my-todo-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { OpenModalComponent } from './open-modal/open-modal.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MapperapiService } from './services/mapperapi.service';
import { PostParams } from './models/post-params';
import { NavParamService } from './services/navparam.service';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NbThemeModule, NbLayoutModule, NbCardModule, NbButtonModule,
   NbInputModule, NbActionsModule, NbIconModule, NbMenuModule, NbContextMenuModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { SandscriptComponent } from './pages/sandscript/sandscript.component';
import { SandservicesComponent } from './pages/sandservices/sandservices.component';
import { VideoListComponent } from './pages/video-list/video-list.component';
import { MapperanywhereComponent } from './pages/mapperanywhere/mapperanywhere.component';
//import { YouTubePlayerModule } from '@angular/youtube-player';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    MyTodoPageComponent,
    AboutPageComponent,
    FooterComponent,
    HeaderComponent,
    OpenModalComponent,
    SandscriptComponent,
    SandservicesComponent,
    VideoListComponent,
    MapperanywhereComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    LoggerModule.forRoot(environment.logging),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxSmartModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    // this will enable the default theme, you can change this by passing `{ name: 'dark' }` to enable the dark theme
    NbThemeModule.forRoot({ name: 'dark' }),
    NbLayoutModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbActionsModule,
    NbEvaIconsModule,
    NbIconModule,
    NbMenuModule.forRoot(),
    NbContextMenuModule
  ],
  providers: [HttpClient, 
    MapperapiService, 
    PostParams, 
    NavParamService],
  bootstrap: [AppComponent]
})
export class AppModule { }
