    <ngx-smart-modal #myModal identifier="myModal" >
      <div *ngIf="myModal.hasData()"  > 
        <br>
        <nb-card>
          <nb-card-header>
            <p>{{ myModal.getData().title }}</p>
          </nb-card-header>
          <nb-card-body>
            <div *ngFor="let img of myModal.getData().imgnames;">
              <p>
                <img src="/assets/MapperAppImages/{{img.imgname}}" class="card-img-top" alt="..." />
              </p>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <button nbButton (click)="myModal.close()">Close</button>
    </ngx-smart-modal>