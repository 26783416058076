import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  
  constructor(private router: Router) {}

  navigateTo(tabName: string) {
    // Navigate to the specified tab
    switch(tabName) {
      case 'mapperanywhere':
        this.router.navigate(['mapperanywhere']);
        break;
      case 'qi':
        this.router.navigate(['sandscript']);
        break;
      case 'about':
        this.router.navigate(['about']);
        break;
      default:
        break;
    }
  }
  
}
