<nb-layout>

  <nb-layout-header fixed>
      <app-header class="w-100"></app-header>
  </nb-layout-header>

  <nb-layout-column>
    <router-outlet></router-outlet>
    <app-open-modal></app-open-modal>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <app-footer class="w-100"></app-footer>
  </nb-layout-footer>

</nb-layout>
