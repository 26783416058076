import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-sandscript',
  templateUrl: './sandscript.component.html',
  styleUrls: ['./sandscript.component.scss']
})
export class SandscriptComponent implements OnInit {
  trustedVideoUrlArray: SafeResourceUrl[] = [];
  youtubeUrlsArray = [
    {
      link: "https://youtube.com/embed/n2-3cqltB5M?autoplay=0&controls=1"
    },
    {
      link: "https://youtube.com/embed/WAToQOzirxA?autoplay=0&controls=1"
    }
  ]

  constructor(
    private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    for (let item of this.youtubeUrlsArray) {
      this.trustedVideoUrlArray.push(this.domSanitizer.bypassSecurityTrustResourceUrl(item.link));
    }
  }

}
